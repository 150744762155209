import { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Spinner } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { RegisterJobObserver, DeregisterJobObserver, GetRecentCompletedData, LoadTrackAndTraceData } from '../Service';
import ManagerContext from '../context/ManagerContext';
import Common from '../module/Common';
import { UtilsService } from '../services/UtilsService';
import { ApiService } from '../services/ApiService';
import { BroadcastService } from '../services/BroadcastService';

import './RecentCompletedJobs.css';

export class RecentCompletedJobs extends Component {

    utilsService = new UtilsService();
    apiService = new ApiService();
    broadcastService = new BroadcastService();
    recentCompletedJobsTable;
    displayName = RecentCompletedJobs.name;
    loadRecentCompletedJobsInterval;


    constructor(props, context) {
        super(props, context);

        this.state = {
            columns: this.generateColumns([]),
            recentCompletedJobs: [],
            loading: true,
            context: context,
            sortField: 'timeCompletedDate',
            sortOrder: -1
        };
    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: true });
            this.loadRecentCompletedJobs();
            this.loadRecentCompletedJobsWithInterval();
        });

        this.broadcastService.registerObserver('clientCodeOrStateSelected', () => {
            ApiService.recentCompletedJobs = [];
            this.setState({ loading: true });
            this.loadRecentCompletedJobs();
        })
    }


    componentWillUnmount() {
        clearInterval(this.loadRecentCompletedJobsInterval);
        this.broadcastService.unregisterObserver('clientCodeOrStateSelected');
    }


    loadRecentCompletedJobs() {
        this.apiService.loadRecentCompletedJobs(this.context.SelectedStateId, this.context.SelectedClientCode)
        .then(recentCompletedJobs => {
            this.setState({ recentCompletedJobs: recentCompletedJobs });
        })
        .finally(() => {
            this.setState({ loading: false });
        });;
    }


    loadRecentCompletedJobsWithInterval() {
        this.loadRecentCompletedJobsInterval = setInterval(() => {
            ApiService.recentCompletedJobs = [];
            this.loadRecentCompletedJobs();
        }, Common.getRefreshTime());
    }


    generateColumns(jobs) {
        const showRouteCode = (jobs?.find(job => job.showRouteCode)) ? true : false; /* Consider showRouteCode as true if at least one of the jobs has showRouteCode:true */
        const showReplayLink = (jobs?.find(job => job.showReplay)) ? true : false; /* Display an extra column to contain the "Replay" link. Do no add the extra column if none of the jobs has showReplay: true */

        const columns = [
            {
                field: 'routeCode', header: 'Route Code', sortable: true, hidden: showRouteCode ? false : true, filter: true,
                body: (row) => <span title={row.routeCode}>{row.routeCode}</span> 
            },
            {
                field: 'dropNumber', header: 'Drop Number', sortable: true, hidden: showRouteCode ? false : true,
                body: (row) => <span title={row.dropNumber}>{row.dropNumber}</span> 
            },
            {
                field: 'driverNumber', header: 'Driver', filter: true, width: '55px',
                body: (row) => <span title={row.driverNumber}>{row.driverNumber}</span> 
            },
            {
                field: 'driverDetail.driverName', header: 'Driver Name',
                body: (row) => <span title={row.driverDetail?.driverName}>{row.driverDetail?.driverName}</span> 
            },
            {
                field: 'stateAbbrev', header: 'State', width: '45px',
                body: (row) => <span title={row.stateAbbrev}>{row.stateAbbrev}</span> 
            },
            {
                field: 'ref1', header: 'Ref 1', sortable: true, filter: true,
                body: (row) => <span title={row.ref1}>{row.ref1}</span> 
            },
            {
                field: 'ref2', header: 'Ref 2', sortable: true, filter: true,
                body: (row) => <span title={row.ref2}>{row.ref2}</span> 
            },
            {
                field: 'shortJobNumber', header: 'Job Number', sortable: true,
                body: (row) => <span title={row.shortJobNumber}>{row.shortJobNumber}</span> 
            },
            {
                field: 'serviceCode', header: 'Service Code', sortable: true, width: '70px',
                body: (row) => <span title={row.serviceCode}>{row.serviceCode}</span> 
            },
            {
                field: 'deliverySuburb', header: 'Deliveries', sortable: true, filter: true,
                body: (row) => <span title={row.deliverySuburb}>{row.deliverySuburb}</span> 
            },
            { 
                field: 'timeCompletedDate', header: 'Time completed', sortable: true,
                body: (row) => {
                    const formattedDate = this.utilsService.formatDateToDefault(row.modified)
                    return <span title={formattedDate}>{formattedDate}</span>;
                }  
            },
            { 
                field: '', header: '', 
                hidden: showReplayLink ? false : true,
                body: (row, column) => {
                    if(row.showReplay) {
                        return <div onClick={(e) => e.stopPropagation()}>
                            <LinkContainer to={'/historical-replay/'+row.clientCode+'/'+row.driverNumber+'/'+row.stateAbbrev+'/'+row.modified}>
                                <Button label="Replay" className="p-button-link text-xs" />
                            </LinkContainer>
                        </div>
                    }
                }
            }
        ];
        
        return columns;
    }


    renderRecentCompletedJobs() {
        const tableColumns = this.generateColumns(this.state?.recentCompletedJobs ?? []);
        const viewIsSmallerThanLaptop = window.innerWidth <= 1200;

        if(this.state?.loading) {
            return <div className="flex flex-row align-items-center gap-2">
                <Spinner color="info" />
                <em>Please wait while we retrieve your recently completed jobs.</em>
            </div>
        } else if(this.state?.recentCompletedJobs?.length === 0) {
            return <p><em>No recent completed jobs.</em></p>
        } else {
            const filteredColumns = tableColumns?.filter(column => !column.hidden); /* Do not render columns with "hidden" flag */
            
            return <DataTable
                ref={(e) => this.recentCompletedJobsTable = e} 
                value={this.state.recentCompletedJobs} 
                className="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
                paginator 
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                onRowClick={(e) => this.utilsService.openJobTrackingPageUsingClientCodeAndState(e.data.jobNumber, e.data.clientCode, e.data.stateAbbrev, this.context.User)}
                autoLayout={true}
                scrollable={viewIsSmallerThanLaptop ? false : true}
                scrollHeight={'calc(50vh - 280px)'}
            >
                {filteredColumns?.map((column, index) => {
                    return <Column 
                        key={index} 
                        field={column.field} 
                        header={column.header} 
                        filter={column.filter}
                        filterElement={column.filterElement}
                        filterMatchMode={'contains'}
                        sortable={column.sortable} 
                        body={column.body}
                        style={{ width: column.width }}
                    ></Column>
                })}
            </DataTable>
        }
    }


    render() {
        return (
            <div className="recent-completed-jobs">
                {this.renderRecentCompletedJobs()}
            </div>
        );


    }
}

RecentCompletedJobs.contextType = ManagerContext;