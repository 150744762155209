import React, { Component } from 'react';
import {Navbar, NavbarText, Container } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';

import ManagerContext from '../../context/ManagerContext';
import { BroadcastService } from '../../services/BroadcastService';
import { UtilsService } from '../../services/UtilsService';

const options = {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
};

export class NavFooter extends Component {

    broadcastService = new BroadcastService();
    utilsService = new UtilsService();
    static displayName = NavFooter.name;
    
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedClientCode: null,
            selectedState: null,
        };
    }


    componentDidMount() {
        setTimeout(() => {
            this.setClientCodeAndStateFromContext();
        })
        
        this.broadcastService.registerObserver('clientCodeOrStateSelected', () => {
            setTimeout(() => {
                this.setState({
                    selectedState: this.utilsService.stateIdToState(this.context?.SelectedStateId),
                    selectedClientCode: this.context?.SelectedClientCode
                });
            })
        })

        this.broadcastService.registerObserver('loginSuccess', () => {
            setTimeout(() => {
                this.setClientCodeAndStateFromContext();
            })
        })

        this.broadcastService.registerObserver('logoutSuccess', () => {
            setTimeout(() => {
                this.setState({
                    selectedClientCode: null,
                    selectedState: null
                })
            })
        })
    }


    componentWillUnmount() {
        this.broadcastService.unregisterObserver('clientCodeOrStateSelected');
        this.broadcastService.unregisterObserver('loginSuccess');
        this.broadcastService.unregisterObserver('logoutSuccess');
    }


    getClientCodesByStateId(stateId) {
        const allowedClients = this.context?.AllowedClients();
        const clientsFilteredByState = allowedClients?.filter(x => x.stateId === stateId);
        const clientCodes = clientsFilteredByState?.map(x => x.clientCode) ?? [];
        return clientCodes;
    }


    onChangeClientCode(value) {
        this.context.SelectedClientCode = value;
        this.setState({selectedClientCode: value});
        this.broadcastService.notify('clientCodeOrStateSelected');
    }


    onChangeState(value) {
        this.context.SelectedStateId = this.utilsService.stateAbbrevToStateId(value);
        this.setState({selectedState: value});

        /* Changing the selected state will filter out the available clientCodes. We will select the first clientCode for the selected state. */
        const firstClientCode = this.getClientCodesByStateId(this.context?.SelectedStateId)?.at(0);
        this.onChangeClientCode(firstClientCode);
    }


    setClientCodeAndStateFromContext() {
        // Set ClientCode as the selectedClientCode(only if selectedClientCode is not yet set).
        if(!this.state?.selectedClientCode) {
            this.setState({ selectedClientCode: this.context.SelectedClientCode });
        }

        // Set State as the selectedState(only if selectedState is not yet set).
        if(!this.state?.selectedState) {
            const state = this.utilsService.stateIdToState(this.context.SelectedStateId);
            this.setState({ selectedState: state });
        }
    }
    

    /**
     * Render the dropdown controls for clientCode and state if logged in.
     * Render a message if not logged in.
     */
    renderDropdownControls() {
        if(this.context.IsLoggedIn()) {
            const allowedStates = this.context?.AllowedStates() ?? [];
            const allowedClientCodes = this.getClientCodesByStateId(this.context?.SelectedStateId);
            
            return <>
                <Dropdown value={this.state?.selectedClientCode} options={allowedClientCodes} placeholder="Select a Client" onChange={(e) => this.onChangeClientCode(e?.value)} />
                <Dropdown value={this.state?.selectedState} options={allowedStates} placeholder="Select a State" onChange={(e) => this.onChangeState(e?.value)} />
            </>
        }

        else {
            return <NavbarText style={{ float: 'center', color: '#ffffff' }}>Please Log In</NavbarText>;
        }
    }
    
    
    render() {
        let context = this.context;
        var refresh = new Date(context.LastRefresh).toLocaleTimeString("en-US", options);

        return (
        <footer>
            <Navbar style={{ backgroundColor: '#2a2c71' }} dark expand="md" fixed="bottom" className="lowerZ">
                <Container fluid>
                    <NavbarText style={{ float: 'left', color: '#ffffff' }}>iLogix 2024</NavbarText>

                    <div className="flex flex-row gap-2">
                       {this.renderDropdownControls()}
                    </div>

                    <NavbarText style={{ float: 'right' }}>
                        {refresh}
                    </NavbarText>
                </Container>
            </Navbar>
            </footer>
        );
  }
}

NavFooter.contextType = ManagerContext;