/**
 * Currently, we use Service.js to house the logic for API call and broadcast/subscription to global events,
 * so moving forward, we will put here the logic for broadcast/subscription.
 */


export class BroadcastService {

    static observers = {
        clientCodeOrStateSelected: [],
        loginSuccess: [],
        logoutSuccess: [],
        authSuccess: [],
    };
    
    
    registerObserver(eventName, methodToRun) {
        BroadcastService.observers[eventName].push(methodToRun);
    }


    registerMultipleObservers(eventNames, methodToRun) {
        eventNames?.forEach(eventName => {
            this.registerObserver(eventName, methodToRun);
        })
    }


    unregisterObserver(eventName) {
        BroadcastService.observers[eventName] = [];
    }
    
    
    notify(eventName) {
        const methodsToRun = BroadcastService.observers[eventName] ?? [];

        methodsToRun.forEach(method => {
            method();
        })
    }

}