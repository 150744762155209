import './JobRow.css';

import React, { Component } from 'react';
import { Input } from 'reactstrap';

import ManagerContext from '../../context/ManagerContext';
import Common from '../../module/Common';

import { JobEdit } from './JobEdit';
import AsnConnect from '../../module/AsnConnector';


// interface JobRowProps {
//     filterKeyword: string; /* we need to highlight any text in the row that matches the filter keyword */
//     OTHER PROPS NOT YET DOCUMENTED IN HERE
// }


export class JobRow extends Component {
    static displayName = JobRow.name;

    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            advanced: {
                date:this.isoToDate(this.props.job.readyFor),
                time:this.isoToTime(this.props.job.readyFor)
            },
            readyFor: this.dateTimeToIsoBrowserLocal(this.isoToDate(this.props.job.readyFor), this.isoToTime(this.props.job.readyFor)),
            driver: (this.props.job.driver ?? ''),
            serviceCode: (this.props.job.service ?? ''),
            changedClass: 'job-row-component cell-fitted text-center'
        }

        this.handleDriver = this.handleDriver.bind(this);
        this.handleService = this.handleService.bind(this);
        this.handleSelectedChange = this.handleSelectedChange.bind(this);

        this.props.addSignal(this.listener, this.props.job.id);
    }

    /**
     * Determines if the job has failed and is ok to upload.
     */
    get jobCancelledAndOkToUpload() {
        if(this.props?.job?.cancelled === true ) {
            return true;
        } else {
            return false;
        }
    }

    isoToDate = (date) => {
        var d = new Date(date);
        return d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    }

    isoToTime = (date) => {
        var d = new Date(date);
        return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2,'0');
    }

    dateTimeToIsoBrowserLocal = (date, time) => {
        return new Date(date + 'T' + time + ':00.000Z');
    }

    handleChange = (e) => {
        let vals = this.state.advanced;
        vals[e.target.dataset.field] = e.target.value;
        this.setState({ advanced: vals });
        this.checkChanges();
    }

    listener = (message,messageData) => {
        let job = this.state.job;
        let driver = this.state.driver;
        let serviceCode = this.state.serviceCode;
        let changedClass = this.state.changedClass;
        let checkChanges = this.checkChanges;
        let setDriver = this.setDriver;
        let setService = this.setService;
        let setDateTime = this.setDateTime;
        let parent = this;
        let readyDate = this.dateTimeToIsoBrowserLocal(this.state.advanced.date, this.state.advanced.time);
        this.setState({readyFor: readyDate});

        let isChanged = this.checkChanges();

        return new Promise(function (resolve, reject) {
            switch (message) {
                case 'Save':
                    if (changedClass === 'modified-job-row cell-fitted text-center') {
                        job.driver = driver;
                        job.service = serviceCode;
                        job.readyFor = readyDate;
                        AsnConnect.JobEdit(job)
                            .then(data => {
                                checkChanges();
                                resolve(job);
                            });
                    } else {
                        resolve('Skip');
                    }
                    break;
                case 'Reset':
                        parent.setOriginalJob();
                        resolve({ success: true, id: job.id });
                    break;
                case 'Book':
                    if (isChanged) {
                        alert("Please clear or save changes before proceeding to release jobs");
                    } else if (job.selected) {
                        AsnConnect.JobBook(job)
                            .then(data => {
                                resolve({ success: true, id: job.id });;
                            });                        
                    } else {
                        resolve({success:false, id:null});
                    }
                    break;
                case 'Cancel':
                    if (isChanged) {
                        alert("Please clear or save changes before proceeding to cancel jobs");
                    } else if (job.selected) {
                        AsnConnect.JobCancel(job)
                            .then(data => {
                                resolve({ success: true, id: job.id });;
                            });
                    } else {
                        resolve({ success: false, id: null });
                    }
                    break;
                case 'Driver':
                    if (job.selected) {
                        //console.log(messageData);
                        setDriver(messageData);
                    }
                    resolve({ success: true, id: job.id });
                    break;
                case 'Service':
                    if (job.selected) {
                        //console.log(messageData);
                        setService(messageData);
                    }
                    resolve({ success: true, id: job.id });
                    break;
                case 'DateTime':
                    if (job.selected) {
                        setDateTime(messageData);
                    }
                    break;
                default:
                    break;
            }
        })
    }

    setOriginalJob = () => {
        let driverNumber = this.state.job.driver ?? '';
        let service = this.state.job.service ?? '';

        let adv = {
            date: this.isoToDate(this.state.job.readyFor),
            time: this.isoToTime(this.state.job.readyFor)
        };

        this.setState({
            driver: driverNumber,
            serviceCode: service,
            advanced: adv
        }, this.checkChanges);
    }

    setDriver = (driverNumber) => {
        this.setState({ driver: driverNumber },this.checkChanges);
    }

    setService = (service) => {
        this.setState({ serviceCode: service },this.checkChanges);
    }

    setDateTime = (dateTime) => {
        this.setState({ advanced: dateTime }, this.checkChanges );
    }

    checkChanges = () => {
        let i = 0;
        if ((this.state.driver ?? '') !== (this.state.job.driver ?? '') ) { i++; }
        if ((this.state.serviceCode ?? '') !== (this.state.job.service ?? '')) { i++; }

        var d = this.dateTimeToIsoBrowserLocal(this.state.advanced.date, this.state.advanced.time);
        if (this.state.readyFor.toString() !== d.toString()) {
            i++;
        }

        var isChanged = false;

        if (i > 0) {
            this.setState({ changedClass: 'modified-job-row cell-fitted text-center' });
            isChanged = true;
        } else {
            this.setState({ changedClass: 'cell-fitted text-center'});
        }

        return isChanged;
    }

    handleSelectedChange = (e) => {
        let checked = e.target.checked;
        this.props.selected(this.props.job.id, checked);
    }

    handleDriver = (e) => {
        let val = e.target.value;
        if (val === '') { val = null;}
        this.setState({ driver: val }, function () { this.checkChanges(); });
    }

    handleService = (e) => {
        let val = e.target.value;
        if (val === '') { val = null; }
        this.setState({ serviceCode: val },function () { this.checkChanges();});
    }


    /**
     * Wrap any text that matches the filterKeyword with "span.search-highlight" 
     */
    renderTextWithSearchHighlight(text) {        
        const textValue = text ? (text).toString() : '';

        if(this.props?.filterKeyword) {
            // We can use str.replace using this.props?.filterKeyword but this will also alter the case(upper/lower) of the original text.
            // To fix this issue, we will extract the matching part from the original string;
            const matchStartIndex = textValue.toUpperCase().indexOf(this.props?.filterKeyword?.toUpperCase());
            const matchedText = textValue.substr(matchStartIndex, this.props?.filterKeyword?.length);
            
            return {
                __html: textValue?.replace(new RegExp(this.props?.filterKeyword, 'gi'), '<span class="search-highlight">'+matchedText+'</span>')
            };
        }
        
        else {
            return {
                __html: '<span>'+textValue+'</span>'
            };
        }
    }
    

    render() {
        const job = this.props.job;
        const stateAbbrev = this.props.stateAbbrev;
        let context = this.context;

        const jobCancelledClass = (this.jobCancelledAndOkToUpload) ? 'cancelled' : '';
        const jobRowClass = `${this.state?.changedClass} ${jobCancelledClass}`;

        return (
            <tr className={jobRowClass} >
                <td><input type="checkbox" checked={job.selected} onChange={this.handleSelectedChange} /></td>
                {context.User.allowHds && <td><input type="checkbox" /></td>}
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.caller)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.reference1)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.reference2)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.contactNumber)}></td>
                
                <td>{ Common.defaultDateFormat(job.bookedOn)}</td>

                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.pickupName)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.pickupAddress1)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.pickupAddress2)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.pickupSuburb)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.pickupPostcode)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.receiverName)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.deliveryAddress1)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.deliveryAddress2)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.deliverySuburb)}></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.deliveryPostcode)}></td>
                <td> <Input type="number" className="input-fitted input-slim" value={this.state.driver} onChange={this.handleDriver}  /></td>
                <td> <Input type="text" className="input-fitted input-slim" onChange={this.handleService} value={this.state.serviceCode} /></td>
                <td dangerouslySetInnerHTML={this.renderTextWithSearchHighlight(job.items)}></td>
                <td>
                    <Input type="date" data-field="date" onChange={this.handleChange} className="input-fitted" value={this.state.advanced.date} bsSize="sm" />
                    <Input type="time" data-field="time" onChange={this.handleChange} className="input-fitted" value={this.state.advanced.time} bsSize="sm" />
                </td>
                <td><JobEdit job={job} stateAbbrev={stateAbbrev}></JobEdit></td>
            </tr>                       
        );     
    }
}

JobRow.contextType = ManagerContext;
